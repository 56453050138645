<template>
  <div>
    <b-card no-body>
      <b-card-header class="pt-1 pb-1 pl-2 pr-2">
        <h5 class="mb-0 flex-grow-1 text-primary">
          Thông tin hồ sơ
        </h5>
      </b-card-header>
      <div class="card-body">
        <b-row>
          <b-col
              cols="12"
              lg="8"
          >
            <dynamic-form
                :id="'filter-form-acreage'"
                :value="document.title"
                placeholder="Tiêu đề hồ sơ"
                :label="'Tiêu đề hồ sơ'"
                @update-value="val => document.title = val"
            />
          </b-col>

          <b-col
              cols="12"
              lg="4"
          >
            <dynamic-form
                :id="'product-form-braafsncasfaffh_id'"
                :value="document.type"
                :type="'select'"
                :label="'Loại hồ sơ'"
                text-field="name"
                value-field="id"
                :options="[{name: 'Loại 1', value: 'type-1'}, {name: 'Loại 2', value: 'type-2'}]"
                @update-value="val => document.type = val"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>


    <b-card no-body>
      <b-card-header class="pt-1 pb-1 pl-2 pr-2">
        <h5 class="mb-0 flex-grow-1 text-primary">
          Thông tin chủ thể
        </h5>
      </b-card-header>
      <div class="card-body">
        <b-row>
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <dynamic-form
                :id="'filter-form-acreage'"
                :value="document.user.name"
                placeholder="Họ và tên"
                :label="'Họ và tên'"
                @update-value="val => document.user.name = val"
            />
          </b-col>

          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <dynamic-form
                :id="'filter-form-acreage'"
                :value="document.user.phone"
                placeholder="Số điện thoại"
                :label="'Số điện thoại'"
                @update-value="val => document.user.phone = val"
            />
          </b-col>

          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <h5 class="font-weight-bold">
              Ngày sinh
            </h5>
            <flat-pickr
                v-model="document.user.birthday"
                class="form-control"
            />
          </b-col>

          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <dynamic-form
                :id="'filter-form-acreage'"
                :value="document.user.homeland"
                placeholder="Quê quán"
                :label="'Quê quán'"
                @update-value="val => document.user.homeland = val"
            />
          </b-col>

          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <dynamic-form
                :id="'filter-form-acreage'"
                :value="document.user.cccd"
                placeholder="CCCD"
                :label="'CCCD'"
                @update-value="val => document.user.cccd = val"
            />
          </b-col>

          <b-col
              cols="12"
          >
            <attachments-create
                ref="attachments"
                v-model="document.user"
                @update-files="val => document.user.files = val"
                @all-updated="handleAllUpdated"
            />
          </b-col>

        </b-row>
      </div>
    </b-card>


    <b-card no-body>
      <b-card-header class="pt-1 pb-1 pl-2 pr-2 mb-2">
        <h5 class="mb-0 flex-grow-1 text-primary">
          Thông tin tài sản
        </h5>
      </b-card-header>
      <div class="card-body">

        <b-row class="mb-3" v-for="idx in 3" :key="'idxidx' + idx">
          <b-col
              cols="12"
          >
            <h4 class="title">Nhập thông tin về tài sản số {{ idx }}</h4>
            <hr>
            <dynamic-form
                :id="'product-form-afbraafsncasfaffh_id'"
                :value="document.assets[idx-1].value"
                :type="'select'"
                :label="'Loại tài sản'"
                text-field="name"
                value-field="value"
                :options="attributes"
                @update-value="val => document.assets[idx-1].value = val"
            />
          </b-col>

          <b-col
              cols="12"
              md="6"
              lg="4"
              v-for="(assetsItem, assetsItemIdx) in (attributes.find(item => item.value === document.assets[idx-1].value) || {}).list"
          >
            <template v-if="assetsItem.type === 'text'">
              <dynamic-form
                  :id="'filter-form-acreaasgge'"
                  :value="document.assets[idx-1].data[assetsItemIdx]"
                  :placeholder="assetsItem.type"
                  :label="assetsItem.name"
                  @update-value="val => document.assets[idx-1].data[assetsItemIdx] = val"
              />
            </template>

            <template v-if="assetsItem.type === 'image'">
              <div class="p-2 border-primary">Chọn hình ảnh</div>
            </template>

            <template v-if="assetsItem.type === 'file'">
              <div class="p-2 border-primary">
                Chọn files đính kèm
              </div>
            </template>
          </b-col>
        </b-row>


      </div>
    </b-card>


    <actions
        :buttons="actionsButton"
        @click="handleActionsButton"
    />
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCol, BMediaAside, BCardHeader, BPagination, BRow, BTable, VBTooltip, BBadge, BButton, BOverlay,
  BCardText, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import store from '@/store'
import {onUnmounted} from '@vue/composition-api'
import vSelect from 'vue-select'
import campaignStoreModule from '@/views/campaign/campaignStoreModule'
import productStoreModule from '@/views/product/productStoreModule'
import TableColumnToggle from '@core/components/TableColumnToggle.vue'
import ability from '@/libs/acl/ability'
import {subject} from '@casl/ability'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import flatPickr from 'vue-flatpickr-component'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useDocument from './useDocument'
import Filters from './Filters.vue'
import FormDetail from "@/views/product-hotel/create/FormDetail.vue";
import AttachmentsCreate from "@/views/components/util/AttachmentsCreate.vue";
import {getRandomInt} from "@/@fake-db/utils";
import Uploads from "@core/components/uploads/Uploads.vue";
import Attachments from "@/views/components/util/AttachmentsCreate.vue";

export default {
  components: {
    Attachments,
    Uploads,
    AttachmentsCreate, FormDetail,
    DynamicForm,
    Filters,
    BAvatar,
    BCard,
    BFormDatepicker,
    BTable,
    TableColumnToggle,
    BPagination,
    BCardHeader,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BMediaAside,
    BBadge,
    BOverlay,
    BButton,
    Actions,
    BFormGroup,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      ability,
      document: {
        type: '',
        id: '',
        title: '',
        user: {
          type: '',
          name: '',
          phone: '',
          birthday: '',
          homeland: '',
          cccd: '',
          files: [],
        },
        assets: [
          {
            name: '',
            value: '',
            data: ['', '', '', '', '', ''],
          },
          {
            name: '',
            value: '',
            data: ['', '', '', '', '', ''],
          },
          {
            name: '',
            value: '',
            data: ['', '', '', '', '', ''],
          },
        ],
      },
    }
  },
  computed: {
    actionsButton() {
      return [this.ACTIONS_BUTTON.CREATE]
    },
  },
  watch: {},
  created() {
  },
  methods: {
    handleAllUpdated(files) {
    },
    gotoProductEdit(product) {
      this.$router.push({
        name: `product-${this.productMenuType}-edit`,
        params: {id: product.id},
      })
    },
    handleActionsButton(btn) {
      if (btn.value === 'create') {
        this.document.id = Math.floor(Math.random() * 1000) + 1
        const listDocument = this.getLocalStorage('documents') || []
        listDocument.unshift(this.document)
        this.setLocalStorage('documents', listDocument)

        this.$toastr('Tạo hồ sơ thành công', 'primary')
        this.$router.push({name: 'document-list'})
      }
    },
    handleCreateCampaign() {
      this.$call(this.createCampaign(), true)
    },
    openModalConfirmDeleteProduct(product) {
      this.currentProduct = product
      this.$refs['modal-confirm-delete-product'].show()
    },
    handleDeleteProduct() {
      this.$call(this.deleteProduct(this.currentProduct.id), true)
          .then(() => {
            const foundIdx = this.products.findIndex(item => item.id === this.currentProduct.id)
            if (foundIdx !== -1) {
              this.products.splice(foundIdx, 1)
            }
          })
    },
    gotoProductDetail({id}) {
      const route = this.$router.resolve({
        name: `product-${this.productMenuType}-detail`,
        params: {id},
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchProducts()
          .finally(() => {
            this.loading = false
          })
    },
    handleClearFilter(initFilter) {
      this.clearFilter(initFilter)
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      this.gotoProductDetail(row)
    },
  },
  mounted() {
    try {
      this.attributes = this.getLocalStorage('attributes') || []
    } catch (e) {
    }
  },
  setup() {
    const CAMPAIGN_STORE_MODULE_NAME = 'campaign'
    const PRODUCT_STORE_MODULE_NAME = 'product'
    if (!store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.registerModule(CAMPAIGN_STORE_MODULE_NAME, campaignStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.unregisterModule(CAMPAIGN_STORE_MODULE_NAME)
    })

    if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    })

    return {
      ...useDocument(),
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.product-badge {
  position: absolute;
  right: 0;
  font-size: .7rem !important;

  &.hot {
    top: .25rem;
  }

  &.corner {
    bottom: .25rem;
  }

  &.hidden-phone {
    top: .25rem;
  }
}

.product-actions-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > * {
    padding: .25rem !important;
  }
}
</style>
